<template>
  <div class="login">
    <el-container>
      <el-main>
        <div class="loginMain">
          <h3 class="loginTil">用户注册</h3>
          <el-form
            :model="dataForm"
            :rules="dataRule"
            ref="dataForm"
            class="registerForm"
            label-width="90px"
            @keyup.enter.native="dataFormSubmit()"
            status-icon
          >
<!--            <el-form-item prop="name" label="用户名：">-->
<!--              <el-input v-model="dataForm.name" placeholder="请输入用户名" />-->
<!--            </el-form-item>-->
            <el-form-item prop="account" label="用户名：">
              <el-input v-model="dataForm.account" placeholder="请输入用户名" />
            </el-form-item>
            <el-form-item prop="mobile" label="手机号码：">
              <el-input v-model="dataForm.mobile" placeholder="请输入手机号码" />
            </el-form-item>
<!--            <el-form-item prop="idType" label="身份证类型：">-->
<!--              <el-select v-model="dataForm.idType" placeholder="请选择身份证类型">-->
<!--                <el-option-->
<!--                  v-for="item in idcardOptions"-->
<!--                  :key="item.id"-->
<!--                  :label="item.name"-->
<!--                  :value="item.id"-->
<!--                />-->
<!--              </el-select>-->
<!--            </el-form-item>-->
<!--            <el-form-item prop="idcard" label="身份证：">-->
<!--              <el-input v-model="dataForm.idcard" placeholder="请输入身份证号码" />-->
<!--            </el-form-item>-->
            <el-form-item prop="password" label="密码：">
              <el-input
                v-model="dataForm.password"
                type="password"
                placeholder="请输入密码"
              />
            </el-form-item>
            <el-form-item prop="password2" label="确认密码：">
              <el-input
                v-model="dataForm.password2"
                type="password"
                placeholder="请再输入一次密码"
              />
            </el-form-item>
<!--            <el-form-item prop="code">-->
<!--              <el-input-->
<!--                class="code_input"-->
<!--                clearable-->
<!--                v-model="dataForm.code"-->
<!--                placeholder="验证码"-->
<!--              />-->
<!--              <el-button class="send" @click="send()" :disabled="!dataForm.mobile" :loading="isLoadingSend">-->
<!--                <span v-show="show">获取验证码</span>-->
<!--                <span v-show="!show" class="count">{{ count }}</span>-->
<!--              </el-button>-->
<!--            </el-form-item>-->
          </el-form>
        </div>
        <div class="registerList">
          <div class="registerJump" @click="loginJump">已有账号？去登录吧</div>
        </div>
      </el-main>
      <el-footer>
        <div class="checkPassBtn">
          <el-button
            class="login-btn-submit"
            type="primary"
            @click="dataFormSubmit()"
            :loading="isLoading"
          >
            注册
          </el-button>
        </div>
      </el-footer>
    </el-container>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        isLoading: false,
        isLoadingSend: false,
        isOriginHei: true, // 按钮隐藏

        show: true, // 初始启用验证码
        count: "", // 初始化验证码次数
        timer: null,

        documentHeight: document.documentElement.clientHeight,  //默认屏幕高度
        dataForm: {
          idType: 0,
          account: null,
          idcard: null,
          mobile: null,
          name: null,
          password: null,
          password2: null,
          code: null,
        },
        idcardOptions: [
          {id: 0, name: "中国内地身份证"},
          {id: 1, name: "中国香港身份证"},
          {id: 2, name: "中国澳门身份证"},
          {id: 3, name: "中国台湾身份证"},
        ],
        dataRule: {
          account: [
            { required: true, message: "用户名不能为空", trigger: "blur" },
          ],
          mobile: [
            { required: true, validator: this.validatePhone, trigger: "blur" },
          ],
          password: [
            { required: true, message: "密码不能为空", trigger: "blur" },
          ],
          password2: [
            { required: true, validator: this.validatePassword, trigger: "blur" },
          ],
          idcard: [
            { required: true, validator: this.idcardCheck, trigger: "blur" },
          ],
        },
      }
    },
    methods: {
      idcardCheck(rule, value, callback) {
        if (this.dataForm.idType === 0) {
          const idrule = /^\d{6}((((((19|20)\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|(((19|20)\d{2})(0[13578]|1[02])31)|((19|20)\d{2})02(0[1-9]|1\d|2[0-8])|((((19|20)([13579][26]|[2468][048]|0[48]))|(2000))0229))\d{3})|((((\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|((\d{2})(0[13578]|1[02])31)|((\d{2})02(0[1-9]|1\d|2[0-8]))|(([13579][26]|[2468][048]|0[048])0229))\d{2}))(\d|X|x)$/
          if (!idrule.test(value)) {
            callback(new Error("你输⼊的身份证长度或格式错误"));
          } else if (value.length == 18) {
            var sum = 0,
                weights = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2],
                codes = "10X98765432"
            for (var i = 0; i < value.length - 1; i++) {
              sum += value[i] * weights[i];
            }
            var last = codes[sum % 11]; //计算出来的最后⼀位⾝份证号码
            if (value[value.length - 1] != last) {
              callback(new Error("你输⼊的身份证匹配失败"));
            }
          } else {
            callback()
          }
        } else {
          callback()
        }
        callback()
      },
      // 联系电话格式验证
      validatePhone(rule, value, callback){
        const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/
        const isPhone = reg.test(value);
        if (!value) {
          callback(new Error("手机号码不能为空"));
        } else if (!isPhone) {
          return callback(new Error("请输入正确的手机号码"));
        }
        callback();
      },
      // 验证密码
      validatePassword(rule, value, callback) {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.dataForm.password) {
          callback(new Error('两次输入密码不一致！'));
        } else {
          callback();
        }
      },
      // 提交表单
      dataFormSubmit() {
        this.isLoading = true;
        this.$confirm('请认真检查注册资料是否有误，是否确认提交？(一旦提交成功，后续资料修改就需要联系佛山市灏海餐饮管理有限公司的相关工作人员进行修改)', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
        this.$refs["dataForm"].validate(valid => {
          if (valid) {
            // 判断两个密码是否一样
            if (this.dataForm.password === this.dataForm.password2) {
              this.$http.httpPost(`/mobile/register`, {
                idcard: this.dataForm.idcard,
                account: this.dataForm.account,
                mobile: this.dataForm.mobile,
                code: this.dataForm.code,
                name: this.dataForm.account,
                password: this.dataForm.password
              }).then( data => {
                if (data && data.code === 0) {
                  this.dataForm.code = null;
                  this.isLoading = false;
                    this.$message({
                      type: 'success',
                      message: '注册成功!',
                      duration: 1500,
                      onClose: () => {
                        this.$router.replace({ name: "Login" });
                      },
                    });
                } else {
                  this.dataForm.code = null;
                  this.isLoading = false;
                  this.$message.error(data.msg);
                }
              });
            } else {
              this.isLoading = false;
            }
          } else {
            this.isLoading = false;
          }
        });
        }).catch(() => {
          this.isLoading = false;
        });
      },
      // 跳转到登录页
      loginJump() {
        this.$router.replace({name: "Login"});
      },
      // 验证码点击事件
      // send() {
      //   this.isLoadingSend = true;
      //   const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/
      //   const isPhone = reg.test(this.dataForm.mobile);
      //   if (!this.dataForm.mobile) {
      //     this.$message.error("联系电话不能为空");
      //     this.isLoadingSend = false;
      //     return false;
      //   } else if (!isPhone) {
      //     this.isLoadingSend = false;
      //     return false;
      //   } else {
      //     const TIME_COUNT = 60; // 倒计时时间
      //     if (!this.timer) {
      //       this.$http.httpGet(`/mobile/sendSMS`, {
      //         phoneNumber: this.dataForm.mobile,
      //         type: 1
      //       }).then( data => {
      //         if (data && data.code === 0) {
      //           // 发短信成功
      //           this.count = TIME_COUNT;
      //           this.show = false;
      //           this.isLoadingSend = false;
      //           this.timer = setInterval(() => {
      //             // 倒计时
      //             if (this.count > 0 && this.count <= TIME_COUNT) {
      //               this.count--;
      //             } else {
      //               // 倒计时完成，重置按钮
      //               this.show = true;
      //               clearInterval(this.timer); // 清除定时器
      //               this.timer = null;
      //             }
      //           }, 1000);
      //         } else {
      //           this.isLoadingSend = false;
      //           this.$message.error(data.msg);
      //         }
      //       });
      //     }
      //   }
      // },
    },
  }
</script>

<style scoped lang="scss">
.login::v-deep {
  height: 100%;
  .el-container {
    height: 100%;
    .el-main {
      padding: 15px;
      .loginMain {
        position: relative;
        margin: 0 auto;
        border: 1px #efefef solid;
        .loginTil {
          font-size: 16px;
          text-align: center;
          padding: 15px 0;
          text-align: center;
          border-bottom: 1px #efefef solid;
        }
        .registerForm {
          position: relative;
          padding: 10px;
          .el-form-item {
            margin: 0 0 15px;
            .el-form-item__label {
              font-size: 12px;
              padding: 0 8px 0 0;
              line-height: 34px;
            }
            .el-form-item__content {
              font-size: 12px;
              line-height: 34px;
              .el-input {
                font-size: 12px;
                .el-input__inner {
                  line-height: 34px;
                  height: 34px;
                }
              }
              .el-select {
                width: 100%;
                .el-input {
                  .el-input__suffix {
                    .el-input__suffix-inner {
                      .el-input__icon {
                        line-height: 34px;
                      }
                    }
                  }
                }
              }
              .el-form-item__error {
                top: 95%;
              }

              .code_input {
                width: calc(100% - 88px);
              }
              .send {
                padding: 10px;
                font-size: 12px;
                margin: 0 0 0 6px;
                width: 82px;
              }
            }
          }
        }
      }
      .registerList {
        padding: 10px 0;
        position: relative;
        overflow: hidden;
        .registerJump {
          color: dodgerblue;
          font-size: 14px;
          text-align: center;
          display: block;
          margin: 15px auto;
        }
      }
    }
    .el-footer {
      height: auto !important;
      padding: 15px;
      .checkPassBtn {
        position: relative;
        width: 100%;
        .el-button {
          font-size: 14px;
          display: block;
          width: 100%;
        }
      }
    }
  }
}
</style>
